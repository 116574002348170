import {newMeasure} from "@gooddata/sdk-model";
import {WORKSPACE_ID} from "../constants";

export const fetchDataForItems = (items, successCallback, backend) => {
    try {
        backend
            .workspace(WORKSPACE_ID)
            .execution()
            .forItems(items)
            .execute()
            .then((result) => {
                result.readAll().then((data) => {
                    successCallback(data);
                });
            });
    } catch (e) {
        console.error("Fetch Data Error:\n", e);
        return null;
    }
};

export const fetchMetrics = (metricsIds, successCallback, backend) => {
    fetchDataForItems(
        metricsIds.map((metricId) => {
            return newMeasure(metricId);
        }),
        successCallback,
        backend
    );
};

export const MetricType = {
    DollarCAD: "DollarCAD",
    CO2e: "CO2e",
};

const getSuffixes = (metricType) => {
    switch (metricType) {
        case MetricType.CO2e:
            return ["Tonnes", "kTonnes", "MTonnes", "GTonnes"];
        case MetricType.DollarCAD:
            return ["", "Thousand", "Million", "Billion", "Trillion"];
        default:
            return [];
    }
};

export const getDisplayText = (value, metricType) => {
    const suffixes = getSuffixes(metricType);

    let mantissa = value;
    let suffixIndex = 0;

    while (mantissa >= 1000 && suffixIndex < suffixes.length) {
        mantissa = mantissa / 1000;
        suffixIndex += 1;
    }
    
    return `${metricType === MetricType.DollarCAD ? "$" : ""}${mantissa
        .toFixed(2)
        .toLocaleString()} ${suffixes[suffixIndex]}`;
};
