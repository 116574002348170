import React from "react";
import "./index.scss";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";

const icon = (
    <LoadingOutlined
        style={{
            fontSize: 64,
            color: "#DF4661",
        }}
        spin
    />
);

const Spinner = () => {
    return <Spin indicator={icon} />;
};

export default Spinner;
