import React, {useEffect, useState} from "react";
import "./index.scss";
import {fetchSummaryMetrics} from "./helpers";
import {useBackend} from "@gooddata/sdk-ui";
import {getDisplayText, MetricType} from "../helpers";

const DataSummaryView = () => {
    const backend = useBackend();

    const [metrics, setMetrics] = useState(null);

    useEffect(() => {
        fetchSummaryMetrics(setMetrics, backend);
    }, [backend]);

    return (
        <>
            {metrics && (
                <div className="data-summary-view darkblue-background smaller-title--regular">
                    <div>
                        <span className="title--bold">
                            {metrics[0]} Buildings
                        </span>{" "}
                        were analyzed.
                    </div>
                    <div>
                        The predicted Carbon Emissions over 28 years (2022-2050)
                        are <br />
                        <span className="title--bold">
                            {getDisplayText(metrics[1], MetricType.CO2e)}
                        </span>
                        .
                    </div>
                    <div>
                        The associated Carbon Costs are <br />
                        <span className="title--bold pink-text">
                            {getDisplayText(metrics[2], MetricType.DollarCAD)}
                        </span>
                        .
                    </div>
                </div>
            )}
        </>
    );
};

export default DataSummaryView;
