const WORKSPACE_ID = "tj3kc4k209emhct2996bawzw436m39sq";

let GOODDATA_DOMAIN;
if (process.env.REACT_APP_ENVIRONMENT === "production") {
    GOODDATA_DOMAIN = "https://audette.on.gooddata.com";
} else {
    GOODDATA_DOMAIN = "https://localhost:3000";
}

export {
    WORKSPACE_ID,
    GOODDATA_DOMAIN,
}
