export const getTableFilters = (filteredTableData) => {
    const defaultFilters = [
        {
            key: "annualCostSavings",
            name: "Annual Cost Savings (CAD)",
            min: 0,
            max: 0,
        },
        {
            key: "annualGreenhouseGasSavings",
            name: "Annual Greenhouse Gas Savings  (Tonnes CO2e)",
            min: 0,
            max: 0,
        },
        {
            key: "incrementalPayback",
            name: "Incremental Payback (years)",
            min: 0,
            max: 0,
        },
    ];

    defaultFilters.forEach((filter) => {
        const data = filteredTableData.map((row) => {
            return parseFloat(row[filter.key]);
        });
        if (data.length > 0) {
            filter.min = Math.min(...data);
            filter.max = Math.max(...data);
        }
        filter.filterValue = [filter.min || null, filter.max || null];
    });

    return defaultFilters;
};
