import {createItemsForApi} from "./helpers";
import {chartGoodDataMap, columnNameToNameMap} from "./constants";
import {extractData} from "./helpers";
import {fetchDataForItems} from "../helpers";

export const fetchData = (dataType, backend, successCallback) => {
    const items = createItemsForApi(chartGoodDataMap[dataType]);
    const attributeColumnNames = chartGoodDataMap[dataType]
        .filter((item) => {
            return item.type === "attribute";
        })
        .map((attribute) => {
            return columnNameToNameMap[attribute.columnName];
        });
    fetchDataForItems(
        items,
        (data) => {
            successCallback(extractData(data, attributeColumnNames));
        },
        backend
    );
};
