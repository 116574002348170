import React from "react";
import {InsightView} from "../InsightsView";
import "./index.scss";

const insight = "abqNLu4Tx4tu";
const style = {height: 400};
const config = {
    mapboxToken:
        "pk.eyJ1IjoiYXVkZXR0ZWFkbWluaXN0cmF0b3IiLCJhIjoiY2wwMDYzNTVqMDJidDNxcnM1bXhqZXBvNSJ9.J3XIPMx53WgsljeovxOQIQ",
};

export function MapView() {
    return (
        <div className="map-view">
            <div className="map-view__title subtitle--regular">
                Average Annual Energy Intensity Values (kWh/m²) by City
            </div>
            <InsightView insightId={insight} config={config} style={style} />
        </div>
    );
}

export default MapView;
