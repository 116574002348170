import React, {useEffect, useMemo, useState} from "react";
import {tableColumns} from "../../constants";
import {Table, Modal, Button} from "antd";
import {Slider} from "antd";
import {getTableFilters} from "./helpers";
import "./index.scss";
import {cloneDeep} from "lodash";
import {FilterOutlined} from "@ant-design/icons";

const MoreDetailsTable = ({tableData, windowWidth}) => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [tableFilters, setTableFilters] = useState(null);

    useEffect(() => {
        if (tableData !== null) {
            setTableFilters(getTableFilters(tableData));
        }
    }, [tableData]);

    const handleFilterOk = (newFilters) => {
        setModalIsVisible(false);
        setTableFilters([...newFilters]);
    };

    const tableDataWithFiltersApplied = useMemo(() => {
        return tableData.filter((row) => {
            let rowIsInRange = true;
            for (
                let filterIndex = 0;
                filterIndex < tableFilters.length;
                filterIndex++
            ) {
                const filter = tableFilters[filterIndex];
                const value = row[filter.key];
                if (
                    value < filter.filterValue[0] ||
                    value > filter.filterValue[1]
                ) {
                    rowIsInRange = false;
                    break;
                }
            }
            return rowIsInRange;
        });
    }, [tableFilters, tableData]);

    const handleFilterCancel = () => {
        setModalIsVisible(false);
    };

    const filterButtonIsVisible = tableData?.length > 1;

    if (windowWidth < 550) return null;

    return (
        <div className="more-details-table-view">
            <div className="more-details-table-view__title-and-button">
                <div className="more-details-table-view__title-and-button__title smaller-subtitle--regular">
                    Detailed Statistics
                </div>
                {filterButtonIsVisible && (
                    <button
                        onClick={() => setModalIsVisible(true)}
                        className="filter-button"
                    >
                        <FilterOutlined style={{size: "1.25rem"}} />
                        Filters
                    </button>
                )}
            </div>
            <MeasureTable
                columns={tableColumns}
                data={tableDataWithFiltersApplied}
            />
            {tableFilters !== null && modalIsVisible && (
                <FilterModal
                    modalIsVisible={modalIsVisible}
                    handleOk={handleFilterOk}
                    handleCancel={handleFilterCancel}
                    filters={tableFilters}
                />
            )}
        </div>
    );
};

const MeasureTable = ({data, columns}) => {
    return <Table dataSource={data} columns={columns} pagination={false} />;
};

const FilterModal = ({modalIsVisible, handleOk, handleCancel, filters}) => {
    const [localFilterState, setLocalFilterState] = useState([...filters]);

    useEffect(() => {
        setLocalFilterState([...filters]);
    }, [filters, modalIsVisible]);

    const resetFilters = () => {
        setLocalFilterState((prev) => {
            return prev.map((prevFilter) => {
                const filterCopy = cloneDeep(prevFilter);
                filterCopy.filterValue = [filterCopy.min, filterCopy.max];
                return filterCopy;
            });
        });
    };

    const onFilterChange = (value, filterKey) => {
        setLocalFilterState((prev) => {
            const prevCopy = [...prev];
            return prevCopy.map((prevFilter) => {
                const filterCopy = cloneDeep(prevFilter);
                if (filterCopy.key === filterKey) {
                    filterCopy.filterValue = value;
                }
                return filterCopy;
            });
        });
    };

    const footer = (
        <div className="custom-footer">
            <Button
                className="ok-button"
                onClick={() => handleOk(localFilterState)}
                type="primary"
            >
                Apply
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
                Cancel
            </Button>
            <Button className="clear-button" onClick={resetFilters} type="link">
                Clear Filters
            </Button>
        </div>
    );

    return (
        <Modal
            title="Add Filters"
            visible={modalIsVisible}
            footer={footer}
            onCancel={handleCancel}
        >
            {localFilterState.map((filter, index) => {
                return (
                    <>
                        <FilterSlider
                            filter={filter}
                            onChange={onFilterChange}
                            filterKey={filter.key}
                            key={`${filter.key}`}
                        />
                        {index !== localFilterState.length - 1 && (
                            <div className="divider" />
                        )}
                    </>
                );
            })}
        </Modal>
    );
};

const FilterSlider = ({filter, onChange, filterKey}) => {
    const getLabelObject = (mark) => {
        return {
            label: <div>{mark.toLocaleString()}</div>,
        };
    };
    const marks = {};
    marks[filter.min] = getLabelObject(filter.min);
    marks[filter.max] = getLabelObject(filter.max);

    return (
        <div className="filter-slider">
            <div className="smaller-body--regular">{filter.name}</div>
            <Slider
                key={filterKey}
                marks={marks}
                range
                value={filter.filterValue}
                onChange={(value) => onChange(value, filterKey)}
                min={filter.min}
                max={filter.max}
            />
        </div>
    );
};

export default MoreDetailsTable;
