import {newMeasure, newAttribute} from "@gooddata/sdk-model";
import {
    columnNameToNameMap,
    streamChartColours,
    remainingEmissionsColor,
    gridDecarbonizationColor,
    REMAINING_EMISSIONS,
    ELECTRICITY_GRID_DECARBONIZATION,
    _ELECTRICITY_GRID_DECARBONIZATION,
} from "./constants";
import {fetchData} from "./api";

const extractColumnNameFromMeasureName = (measureName) => {
    const index = Object.keys(columnNameToNameMap).findIndex((columnName) => {
        return measureName.includes(columnName);
    });
    if (index !== -1) return Object.keys(columnNameToNameMap)[index];
    return null;
};

export const extractData = (data, attributeColumnNames) => {
    const measures = data.headerItems[0];
    const attributes = data.headerItems[1];

    const dataArray = [];
    data.data.map((datum, index) => {
        let correspondingMeasureName =
            columnNameToNameMap[
                extractColumnNameFromMeasureName(
                    measures[0][index].measureHeaderItem.name
                )
            ];
        datum.forEach((measureData, dataIndex) => {
            if (!dataArray[dataIndex]) {
                const newObject = {};
                attributes.forEach((attribute, attributeIndex) => {
                    const attributeValue = attribute[
                        dataIndex
                    ].attributeHeaderItem.name
                        .replace("x_", "")
                        .replace("a_", "");

                    const attributeName = attributeColumnNames[attributeIndex];
                    newObject[attributeName] = attributeValue;
                });

                newObject[correspondingMeasureName] = measureData;
                dataArray.push(newObject);
            } else {
                const object = dataArray[dataIndex];
                object[correspondingMeasureName] = measureData;
                dataArray[dataIndex] = object;
            }
        });
    });
    return dataArray;
};

export const calculateTotalEmissionsByYear = (points) => {
    return points.map((point) => {
        let total = 0;
        Object.keys(point).forEach((key) => {
            total += parseInt(point[key]);
        });
        return total;
    });
};

export const createItemsForApi = (items) => {
    return items.map((item) => {
        if (item.type === "attribute") {
            return newAttribute(item.columnName);
        } else {
            return newMeasure(item.columnName, (m) =>
                m.aggregation(item.aggregation)
            );
        }
    });
};

export const formatStreamChartData = (dataFromAPI) => {
    const groupedPoints = {};
    const measures = [];
    dataFromAPI.forEach((point) => {
        if (groupedPoints[point.year] === undefined) {
            groupedPoints[point.year] = {};
        }

        if (groupedPoints[point.year][point.measureName] !== undefined) {
            groupedPoints[point.year][point.measureName] += parseFloat(
                point.emissionsSavings
            );
        } else {
            groupedPoints[point.year][point.measureName] = parseFloat(
                point.emissionsSavings
            );
        }
        measures.push(point.measureName);
    });

    const formattedPoints = Object.entries(groupedPoints).map(
        ([_, data]) => data
    );
    return {
        points: formattedPoints,
        measures: measures,
    };
};

export const buildChartColors = (measures) => {
    // we want the chart to start with brighter colours (more aesthetic) so we start with the last index first
    let colourIndex = measures.length - 3; // -3 because we exlude the remaining emissions  and grid decarbonization in this calculation

    return measures.map((measure) => {
        if (measure === REMAINING_EMISSIONS) {
            return remainingEmissionsColor;
        }
        if (measure === ELECTRICITY_GRID_DECARBONIZATION) {
            return gridDecarbonizationColor;
        }
        const colour =
            streamChartColours[colourIndex % streamChartColours.length];
        colourIndex--;
        return colour;
    });
};

export const getAllMeasureNames = (streamChartData) => {
    if (streamChartData === null || streamChartData.points.length < 1)
        return null;
    const measures = Object.keys(streamChartData.points[0]).filter(
        (name) => name !== ""
    );

    const remainingEmissionsIndex = measures.indexOf(REMAINING_EMISSIONS);
    if (remainingEmissionsIndex !== -1)
        measures.splice(remainingEmissionsIndex, 1);

    const gridDecarbonizationIndex = measures.indexOf(
        ELECTRICITY_GRID_DECARBONIZATION
    );
    if (gridDecarbonizationIndex !== -1)
        measures.splice(gridDecarbonizationIndex, 1);
    return measures;
};

export const getTotalEmissionsByYear = (streamChartData) => {
    if (streamChartData === null) return null;
    return calculateTotalEmissionsByYear(streamChartData.points);
};

export const filterCardData = (cardData, selectedMeasures) => {
    if (cardData === null) return null;
    return cardData.filter((data) => {
        if (
            selectedMeasures.includes(data.measureName) &&
            data.measureName !== REMAINING_EMISSIONS
        ) {
            return true;
        }
        return false;
    });
};

export const filterTableData = (allTableData, selectedMeasures) => {
    if (allTableData === null) return null;
    return allTableData.filter((data) => {
        if (selectedMeasures.includes(data.measureName)) return true;
        return false;
    });
};

export const filterStreamChartData = (
    streamChartData,
    selectedMeasures,
    totalEmissionsByYear
) => {
    if (!streamChartData) return null;

    return streamChartData.points.map((point, yearIndex) => {
        const newPoint = {};
        let total = 0;
        Object.keys(point).forEach((measureName) => {
            if (
                selectedMeasures.includes(measureName) &&
                measureName !== REMAINING_EMISSIONS
            ) {
                newPoint[measureName] = point[measureName];
                total += parseInt(newPoint[measureName]);
            }
        });
        newPoint[REMAINING_EMISSIONS] = totalEmissionsByYear[yearIndex] - total;
        return newPoint;
    });
};

export const calculateTotalSavings = (forKey, filteredCardData) => {
    if (filteredCardData === null) return null;
    return filteredCardData.reduce((prev, curr) => {
        return prev + parseFloat(curr[forKey]);
    }, 0);
};

export const fetchAllData = (
    setStreamChartData,
    setCardData,
    setAllTableData,
    backend
) => {
    if (backend) {
        fetchData("streamChartData", backend, (data) =>
            setStreamChartData(formatStreamChartData(data))
        );
        fetchData("cardData", backend, setCardData);
        fetchData("tableData", backend, setAllTableData);
    }
};
