import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BackendProvider} from "@gooddata/sdk-ui";
import {backend} from "./context/goodData";

import "@gooddata/sdk-ui-ext/styles/css/main.css";
import "@gooddata/sdk-ui-geo/styles/css/main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BackendProvider backend={backend}>
            <App />
        </BackendProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
