import sdk, {getFactory} from "@gooddata/api-client-bear";
import {GOODDATA_DOMAIN} from "../constants";

const authenticateWithGoodData = (setAuthenticated) => {
    const userEmail = process.env.REACT_APP_USER_EMAIL;
    const userPassword = process.env.REACT_APP_USER_PASSWORD;

    const bearClient = getFactory({domain: GOODDATA_DOMAIN});
    bearClient.user
        .login(userEmail, userPassword)
        .then((response) => {
            setAuthenticated(true);
            console.log("Login OK");
        })
        .catch((apiError) =>
            console.error(
                "Login failed",
                apiError,
                "\n\n",
                apiError.responseBody
            )
        );
};

export const gooddataLogin = async (setAuthenticated, backend) => {
    const isAuthenticated = await backend?.isAuthenticated();
    if (isAuthenticated) {
        setAuthenticated(true);
    } else {
        authenticateWithGoodData(setAuthenticated);
    }
};
