import { GOODDATA_DOMAIN } from "../constants";

import bearFactory, {
    ContextDeferredAuthProvider,
} from "@gooddata/sdk-backend-bear";

const backend = bearFactory()
    .onHostname(GOODDATA_DOMAIN)
    .withAuthentication(new ContextDeferredAuthProvider());

export {backend};
