import React from "react";
import "./index.scss";
import BuildingImage from "./BuildingImage.png";

const DashboardSummaryView = () => {
    return (
        <div className="dashboard-summary-view">
            <div className="dashboard-summary-view__text body">
                This dashboard summarizes the{" "}
                <span className="body--bold">results</span> of the{" "}
                <span className="body--bold">buildings analyzed</span> and
                outlines the current{" "}
                <span className="body--bold">
                    greenhouse gas emission profile{" "}
                </span>
                and <span className="body--bold">energy performance</span> of
                the participating buildings, in addition to the opportunities to
                achieve emissions reductions. All building data is presented at
                an aggregated level.
            </div>
            <img src={BuildingImage} alt="Eco Trust" />
        </div>
    );
};

export default DashboardSummaryView;
