import React from "react";
import "../index.scss";
import Cards from "./Cards";
import {
    REMAINING_EMISSIONS,
    ELECTRICITY_GRID_DECARBONIZATION,
    measureNamesToDescriptionsMap,
} from "../constants";
import {Select, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

const {Option} = Select;

const infoIconStyle = {
    fontSize: "1rem",
    color: "f5f5f5",
    transform: "translateY(-1em)",
};

const InteractiveDropdownAndCardsHeader = ({
    allMeasureNames,
    setSelectedMeasures,
    totalAnnualCarbonCostSavingsForSelectedMeasures,
    totalAnnualGreenhouseGasSavingsForSelectedMeasures,
}) => {
    return (
        <div className="interactive-measures-view__header gray-background">
            <div className="interactive-measures-view__header__title subtitle--regular">
                What actions can be taken to mitigate future{" "}
                <span className="subtitle--bold">Carbon Costs</span> and{" "}
                <span className="subtitle--bold">Greenhouse Gas Emissions</span>
                ?
            </div>
            <div className="interactive-measures-view__header__dropdown-and-title-view">
                <div className="smaller-subtitle--regular">
                    Select Measures
                    <Tooltip
                        placement="top"
                        title={
                            <div>
                                Measures are actions that can be implemented to
                                reduce a building's carbon footprint
                            </div>
                        }
                    >
                        <InfoCircleOutlined style={infoIconStyle} />
                    </Tooltip>{" "}
                    to View Statistics
                </div>
                <Dropdown
                    options={allMeasureNames}
                    onChange={(options) => {
                        options.unshift(ELECTRICITY_GRID_DECARBONIZATION);
                        options.unshift(REMAINING_EMISSIONS);
                        setSelectedMeasures(options);
                    }}
                />
            </div>
            <Cards
                totalAnnualCarbonCostSavingsForSelectedMeasures={
                    totalAnnualCarbonCostSavingsForSelectedMeasures
                }
                totalAnnualGreenhouseGasSavingsForSelectedMeasures={
                    totalAnnualGreenhouseGasSavingsForSelectedMeasures
                }
            />
        </div>
    );
};

const Dropdown = ({options, onChange}) => {
    return (
        <>
            <Select onChange={onChange} mode="multiple" placeholder="Select">
                {options.map((option) => {
                    return (
                        <Option key={option} value={option}>
                            <Tooltip
                                placement="top"
                                key={option}
                                title={
                                    <div>
                                        {measureNamesToDescriptionsMap[option]}
                                    </div>
                                }
                            >
                                <div>{option}</div>
                            </Tooltip>
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default InteractiveDropdownAndCardsHeader;
