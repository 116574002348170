import React from "react";
import "../index.scss";
import {getDisplayText} from "../../helpers";
import {MetricType} from "../../helpers";

const Cards = ({
    totalAnnualGreenhouseGasSavingsForSelectedMeasures,
    totalAnnualCarbonCostSavingsForSelectedMeasures,
}) => {
    return (
        <div className="cards">
            <Card
                title="Greenhouse Gas Savings"
                subtitle={duration}
                stats={`${getDisplayText(
                    totalAnnualGreenhouseGasSavingsForSelectedMeasures,
                    MetricType.CO2e
                )}`}
            />
            <Card
                title="Carbon Cost Savings"
                subtitle={duration}
                stats={`${getDisplayText(
                    totalAnnualCarbonCostSavingsForSelectedMeasures,
                    MetricType.DollarCAD
                )}`}
            />
            <Card
                title="Greenhouse Gas Savings is equivalent to"
                subtitle={duration}
                stats={`${calculateNumberOfCarsFromEmissions(
                    totalAnnualGreenhouseGasSavingsForSelectedMeasures
                ).toLocaleString()} Cars`}
            />
        </div>
    );
};

const Card = ({title, subtitle, stats}) => {
    return (
        <div className="card">
            <div className="smaller-subtitle--regular">{title}</div>
            <div className="smaller-subtitle--regular lightGrayText">
                {subtitle}
            </div>
            <div className="pink-text semi-large-text--bold">{stats}</div>
        </div>
    );
};

const calculateNumberOfCarsFromEmissions = (emissions) => {
    return Math.round(emissions / (28 * 4.6));
};

const duration = "2022-2050";

export default Cards;
