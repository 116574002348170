import measuresAndDescriptionsJSON from "./MeasureNamesToDescriptionsMap.json";

export const REMAINING_EMISSIONS = "Remaining Emissions";
export const ELECTRICITY_GRID_DECARBONIZATION =
    "Electricity Grid Decarbonization";

export const _ELECTRICITY_GRID_DECARBONIZATION =
    "a_Electricity Grid Decarbonization";

export const measureNamesToDescriptionsMap = measuresAndDescriptionsJSON;

export const tableColumns = [
    {
        title: "Measure Name",
        dataIndex: "measureName",
        key: "measureName",
        sorter: {
            compare: (a, b) => a.measureName.localeCompare(b.measureName),
        },
    },
    {
        title: "Annual Cost Savings (CAD)",
        dataIndex: "annualCostSavings",
        key: "annualCostSavings",
        render: (savings) => `$${parseFloat(savings).toLocaleString()}`,
        sorter: {
            compare: (a, b) => a.annualCostSavings - b.annualCostSavings,
        },
    },
    {
        title: "Annual Greenhouse Gas Savings  (Tonnes CO2e)",
        dataIndex: "annualGreenhouseGasSavings",
        key: "annualGreenhouseGasSavings",
        render: (savings) => `${parseFloat(savings).toLocaleString()}`,
        sorter: {
            compare: (a, b) =>
                a.annualGreenhouseGasSavings - b.annualGreenhouseGasSavings,
        },
    },
    {
        title: "Incremental Payback (years)",
        dataIndex: "incrementalPayback",
        key: "incrementalPayback",
        render: (years) => `${parseFloat(years).toFixed(1)}`,
        sorter: {
            compare: (a, b) => a.incrementalPayback - b.incrementalPayback,
        },
    },
    {
        title: "Number of Buildings",
        dataIndex: "numberOfBuildings",
        key: "numberOfBuildings",
        render: (numberOfBuildings) =>
            `${parseFloat(numberOfBuildings).toLocaleString()}`,
        sorter: {
            compare: (a, b) => a.numberOfBuildings - b.numberOfBuildings,
        },
    },
];

export const columnNameToNameMap = {
    "label.out_carbon_reduction_measure_savings_by_fuel_type.measure_name":
        "measureName",
    "datemonth.year.default": "year",
    "fact.out_carbon_reduction_measure_savings_by_fuel_type.emissions_savings_tonnes_co2e":
        "emissionsSavings",
    "fact.out_carbon_reduction_measure_savings_by_fuel_type.emissionsliabilitysavingscad":
        "savingsCAD",
    "fact.out_carbon_reduction_measures.total_utility_cost_savings_cad_annual":
        "annualCostSavings",
    "fact.out_carbon_reduction_measures.incremental_payback_years":
        "incrementalPayback",
    "label.out_carbon_reduction_measures.measure_name": "measureName",
    "fact.out_carbon_reduction_measures.total_emissions_savings_tonnes_co2e_annual":
        "annualGreenhouseGasSavings",
    "attr.out_building_information.building_name": "numberOfBuildings",
};

export const dataTypes = {
    streamChartData: "streamChartData",
    cardData: "cardData",
    tableData: "tableData",
};

export const chartGoodDataMap = {
    streamChartData: [
        {
            columnName:
                "label.out_carbon_reduction_measure_savings_by_fuel_type.measure_name",
            type: "attribute",
        },
        {
            columnName: "datemonth.year.default",
            type: "attribute",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measure_savings_by_fuel_type.emissions_savings_tonnes_co2e",
            type: "measure",
            aggregation: "sum",
        },
    ],
    cardData: [
        {
            columnName:
                "label.out_carbon_reduction_measure_savings_by_fuel_type.measure_name",
            type: "attribute",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measure_savings_by_fuel_type.emissions_savings_tonnes_co2e",
            type: "measure",
            aggregation: "sum",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measure_savings_by_fuel_type.emissionsliabilitysavingscad",
            type: "measure",
            aggregation: "sum",
        },
    ],
    tableData: [
        {
            columnName: "label.out_carbon_reduction_measures.measure_name",
            type: "attribute",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measures.total_utility_cost_savings_cad_annual",
            type: "measure",
            aggregation: "sum",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measures.total_emissions_savings_tonnes_co2e_annual",
            type: "measure",
            aggregation: "sum",
        },
        {
            columnName:
                "fact.out_carbon_reduction_measures.incremental_payback_years",
            type: "measure",
            aggregation: "avg",
        },
        {
            columnName: "attr.out_building_information.building_name",
            type: "measure",
            aggregation: "count",
        },
    ],
};

export const streamChartColours = [
    "#11B5AE",
    "#4046CA",
    "#F68512",
    "#DE3C82",
    "#7E84F9",
    "#71E06A",
    "#7326D3",
    "#E7C600",
    "#CA5C02",
    "#008F5D",
    "#BCE930",
];

export const remainingEmissionsColor = "#AFAFAF";
export const gridDecarbonizationColor = "#B1E4E3";
