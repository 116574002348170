import React from "react";
import "./index.scss";
import EcoTrustLogo from "./EcoTrustLogo.png";

const EcoTrustIntroductionHeader = () => {
    return (
        <div className="ecotrust-introduction-header darkblue-background">
            <div className="title--bold">
                Accelerating Retrofits in Commercial Buildings:
                <br />
                Building Analysis Dashboard
            </div>
            <div className="body">
                The Accelerating Retrofits in Commercial Buildings pilot project
                provided a cohort of building owners in Calgary and Edmonton
                with a digital, simplified, low-carbon retrofit identification
                process.
                <br />
                <br />
                The energy efficiency improvements identified will allow
                building owners to pursue retrofit opportunities, including
                exploring alternative financing mechanisms. For more information
                on the project, visit our
                <a href="https://albertaecotrust.com/grants/accelerating-retrofits-in-commercial-buildings" target="_blank">
                    {" "}
                    website
                </a>
                .
            </div>
            <div>
                <img src={EcoTrustLogo} alt="Eco Trust" />
            </div>
        </div>
    );
};

export default EcoTrustIntroductionHeader;
