import React from "react";
import {InsightView as GoodDataInsightsView} from "@gooddata/sdk-ui-ext";

export function InsightView({titleText, insightId, config, style}) {
    return (
        <div style={style}>
            <GoodDataInsightsView
                insight={insightId}
                showTitle={titleText}
                config={config}
            />
        </div>
    );
}

export default InsightView;
