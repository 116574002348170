import "./index.scss";
import React, {useEffect, useMemo, useState} from "react";
import {useBackend} from "@gooddata/sdk-ui";
import "antd/dist/antd.css";
import {
    ELECTRICITY_GRID_DECARBONIZATION,
    REMAINING_EMISSIONS,
} from "./constants";

import InteractiveDropdownAndCardsHeader from "./components/InteractiveDropdownAndCardsHeader";
import StreamDiagram from "./components/StreamDiagram";
import MoreDetailsTable from "./components/MoreDetailsTable";

import useWindowWidth from "../hooks/useWindowWidth";

import {
    fetchAllData,
    getTotalEmissionsByYear,
    getAllMeasureNames,
    filterCardData,
    filterTableData,
    filterStreamChartData,
    calculateTotalSavings,
} from "./helpers";

const InteractiveMeasureView = () => {
    const backend = useBackend();

    const windowWidth = useWindowWidth();

    const [streamChartData, setStreamChartData] = useState(null);
    const [selectedMeasures, setSelectedMeasures] = useState([
        REMAINING_EMISSIONS,
        ELECTRICITY_GRID_DECARBONIZATION,
    ]);
    const [allTableData, setAllTableData] = useState(null);
    const [cardData, setCardData] = useState(null);

    const totalEmissionsByYear = useMemo(() => {
        return getTotalEmissionsByYear(streamChartData);
    }, [streamChartData]);

    const allMeasureNames = useMemo(() => {
        return getAllMeasureNames(streamChartData);
    }, [streamChartData]);

    const filteredCardData = useMemo(() => {
        return filterCardData(cardData, selectedMeasures);
    }, [cardData, selectedMeasures]);

    const filteredTableData = useMemo(() => {
        return filterTableData(allTableData, selectedMeasures);
    }, [selectedMeasures, allTableData]);

    const filteredStreamData = useMemo(() => {
        return filterStreamChartData(
            streamChartData,
            selectedMeasures,
            totalEmissionsByYear
        );
    }, [selectedMeasures, streamChartData, totalEmissionsByYear]);

    const totalAnnualGreenhouseGasSavingsForSelectedMeasures = useMemo(() => {
        return calculateTotalSavings("emissionsSavings", filteredCardData);
    }, [filteredCardData]);

    const totalAnnualCarbonCostSavingsForSelectedMeasures = useMemo(() => {
        return calculateTotalSavings("savingsCAD", filteredCardData);
    }, [filteredCardData]);

    useEffect(() => {
        fetchAllData(setStreamChartData, setCardData, setAllTableData, backend);
    }, [backend]);

    return (
        <div className="interactive-measures-view">
            {filteredStreamData &&
                allMeasureNames &&
                filteredTableData &&
                totalAnnualGreenhouseGasSavingsForSelectedMeasures !== null &&
                totalAnnualCarbonCostSavingsForSelectedMeasures !== null && (
                    <>
                        <InteractiveDropdownAndCardsHeader
                            allMeasureNames={allMeasureNames}
                            setSelectedMeasures={setSelectedMeasures}
                            totalAnnualCarbonCostSavingsForSelectedMeasures={
                                totalAnnualCarbonCostSavingsForSelectedMeasures
                            }
                            totalAnnualGreenhouseGasSavingsForSelectedMeasures={
                                totalAnnualGreenhouseGasSavingsForSelectedMeasures
                            }
                        />
                        <StreamDiagram
                            selectedMeasures={selectedMeasures}
                            filteredStreamData={filteredStreamData}
                            windowWidth={windowWidth}
                        />
                        <MoreDetailsTable
                            tableData={filteredTableData}
                            windowWidth={windowWidth}
                        />
                    </>
                )}
        </div>
    );
};

export default InteractiveMeasureView;
