import React from "react";
import {buildChartColors} from "../helpers";
import {format} from "d3-format";
import {ResponsiveStream} from "@nivo/stream";

const StreamDiagram = ({filteredStreamData, selectedMeasures, windowWidth}) => {
    if (windowWidth < 1114) {
        return null;
    }

    return (
        <div className="interactive-measures-view__stream-chart-view">
            <div className="interactive-measures-view__stream-chart-view__subtitle smaller-subtitle--regular">
                2022 to 2050 Building Emissions Profile{" "}
                <span>{`${
                    selectedMeasures.length > 2
                        ? `with ${selectedMeasures.length - 2} Measure${
                              selectedMeasures.length - 2 > 1 ? "s" : ""
                          } Selected`
                        : ""
                }`}</span>
            </div>
            <div style={{height: 500, width: "100%"}}>
                <ResponsiveStream
                    data={filteredStreamData}
                    keys={selectedMeasures}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: "bottom",
                        format: (i) => `'${i + 22}`,
                        tickSize: 3,
                        tickPadding: 5,
                        legend: "Year",
                        legendPosition: "middle",
                        legendOffset: 36,
                    }}
                    axisLeft={{
                        orient: "left",
                        format: (num) => format(".2s")(num),
                        tickSize: 3,
                        tickPadding: 5,
                        legend: "Emissions (Tonnes CO2e)",
                        legendPosition: "middle",
                        legendOffset: -50,
                    }}
                    legends={[
                        {
                            anchor: "top-right",
                            direction: "column",
                            translateX: 100,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: "#999999",
                            symbolSize: 12,
                            symbolShape: "circle",
                        },
                    ]}
                    colors={buildChartColors(selectedMeasures)}
                    offsetType="none"
                    margin={{
                        top: 0,
                        right: 210,
                        bottom: 50,
                        left: 210,
                    }}
                    enableStackTooltip={true}
                />
            </div>
        </div>
    );
};

export default StreamDiagram;
