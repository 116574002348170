import {fetchMetrics} from "../helpers";

export const fetchSummaryMetrics = (setMetrics, backend) => {
    const buildingCountMetricId = "aabycxyZOQSt";
    const totalEmissionsId = "aajNBJy7IF09";
    const totalCarbonCostId = "aa3XPGk8nH1j";

    fetchMetrics(
        [buildingCountMetricId, totalEmissionsId, totalCarbonCostId],
        (data) => {
            setMetrics(data.data.flat());
        },
        backend
    );
};
