import React from "react";
import AudetteLogo from "./AudetteLogo.png";
import RBCLogo from "./RBCLogo.png";
import "./index.scss";

const Footer = () => {
    return (
        <div className="footer gray-background">
            <div className="smaller-body--bold">Partners</div>
            <div className="footer__logos">
                <a href="https://audette.io/">
                    <img
                        className="footer__logos__audette"
                        src={AudetteLogo}
                        alt="Audette"
                    />
                </a>
                <a href="https://www.rbc.com/">
                    <img
                        className="footer__logos__rbc"
                        src={RBCLogo}
                        alt="RBC"
                    />
                </a>
            </div>
        </div>
    );
};

export default Footer;
