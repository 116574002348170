import React, {useEffect, useState} from "react";
import {useBackend} from "@gooddata/sdk-ui";
import {InsightView} from "../InsightsView";
import {fetchPercentageDifference} from "./helpers";
import {Tooltip} from "antd";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import "./index.scss";
import useWindowWidth from "../hooks/useWindowWidth";

const BenchmarkView = () => {
    const [percentage, setPercentage] = useState(null);

    const backend = useBackend();

    const windowWidth = useWindowWidth();

    useEffect(() => {
        fetchPercentageDifference(backend, setPercentage);
    }, [backend]);

    return (
        <div className="benchmark-view">
            <div className="subtitle--regular">
                How do{" "}
                <span className="subtitle--bold">
                    Average Annual Energy Intensity Values{" "}
                </span>
                for the analyzed buildings compare with buildings of the same
                type in similar{" "}
                <span className="subtitle--bold">Climate Zones?</span>
            </div>
            <div className="benchmark-view__body">
                {percentage && (
                    <Card
                        title="Average Annual Energy Intensity Values"
                        stats={`${Math.round(percentage)}%`}
                        subtitle="against benchmark"
                    />
                )}
                <BarChartInsight windowWidth={windowWidth} />
            </div>
        </div>
    );
};

const Card = ({title, stats, subtitle}) => {
    const arrowIconStyle = {
        fontSize: "3rem",
        color: "#DF4661",
    };

    const infoIconStyle = {
        fontSize: "1rem",
        color: "f5f5f5",
    };

    return (
        <div className="card">
            <div className="smaller-subtitle--bold">{title}</div>
            <div className="card__stats">
                <div className="large-text--bold pink-text">{stats}</div>
                {stats > 0 ? (
                    <ArrowDownOutlined style={arrowIconStyle} />
                ) : (
                    <ArrowUpOutlined style={arrowIconStyle} />
                )}
            </div>
            <div className="card__subtitle">
                <div className="smaller-subtitle--regular">{subtitle}</div>
                <Tooltip
                    placement="top"
                    title={
                        <div>
                            Benchmarks are calculated using the{" "}
                            <a href="https://www.energy.gov/eere/buildings/building-performance-database-bpd">
                                Building Performance Database
                            </a>
                        </div>
                    }
                >
                    <InfoCircleOutlined style={infoIconStyle} />
                </Tooltip>
            </div>
        </div>
    );
};

const BarChartInsight = ({windowWidth}) => {
    const insight = "aayuyjRccm4k";
    const style = {height: 400, width: "100%"};
    if (windowWidth < 500) return null;
    return <InsightView insightId={insight} style={style} />;
};

export default BenchmarkView;
