import "./App.scss";
import {WorkspaceProvider, useBackend} from "@gooddata/sdk-ui";
import {useState, useEffect} from "react";
import {gooddataLogin} from "./helpers/authHelpers";
import InteractiveMeasureView from "./components/InteractiveMeasureView";
import BenchmarkView from "./components/BenchmarkView";
import {WORKSPACE_ID} from "./constants";
import MapView from "./components/MapView";
import EcoTrustIntroductionHeader from "./components/EcoTrustIntroductionHeader";
import DashboardSummaryView from "./components/DashboardSummaryView";
import Footer from "./components/Footer";
import Spinner from "./components/Spinner";
import DataSummaryView from "./components/DataSummaryView";

function App() {
    const backend = useBackend();
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (backend && !authenticated) gooddataLogin(setAuthenticated, backend);
    }, [backend]);

    return authenticated ? (
        <WorkspaceProvider workspace={WORKSPACE_ID}>
            <div className="App">
                <div className="eco-trust-public-dashboard-view">
                    <EcoTrustIntroductionHeader />
                    <DashboardSummaryView />
                    <DataSummaryView />
                    <MapView />
                    <BenchmarkView />
                    <InteractiveMeasureView />
                    <Footer />
                </div>
            </div>
        </WorkspaceProvider>
    ) : (
        <div className="App__loading">
            <Spinner />
        </div>
    );
}

export default App;
